<template>
  <section>
    <kn-auto-complete
      label="Estado"
      v-model="address.estado"
      :rules="[rules.required]"
      :items="states"
      :loading="loading"
      :disabled="disabled"
      @input="getZips(address.estado)"
    />
    <kn-auto-complete
      label="Ciudad"
      v-model="address.ciudad"
      :rules="mandatoryCityRules"
      :isMandatory="mandatoryCityRules.length > 0"
      :items="zips"
      item-text="nombre_ciudad"
      item-value="nombre_ciudad"
      :loading="loading"
      :disabled="disabled"
      @input="filterZipByCityOrMunicipality(address.ciudad, address.municipio)"
    />
    <kn-auto-complete
      label="Municipio"
      v-model="address.municipio"
      :rules="mandatoryMunicipalityRules"
      :isMandatory="mandatoryMunicipalityRules.length > 0"
      :items="zips"
      item-text="nombre_municipio"
      item-value="nombre_municipio"
      :loading="loading"
      :disabled="disabled"
      @input="filterZipByCityOrMunicipality(address.ciudad, address.municipio)"
    />
    <kn-auto-complete
      label="Código postal"
      v-model="address.code"
      :rules="[rules.required]"
      :items="zipsByCity"
      item-text="codigo_postal"
      item-value="codigo_postal"
      :loading="loading"
      :disabled="disabled"
      @input="filterColByZip(address.code)"
    />
    <kn-auto-complete
      label="Colonia"
      v-model="address.colonia"
      :rules="[rules.required]"
      :items="colonias"
      item-text="nombre_asentamiento"
      item-value="nombre_asentamiento"
      :loading="loading"
      :disabled="disabled"
      @input="getZipId()"
    />
    <kn-text-field
      label="Calle"
      v-model="address.calle"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <kn-text-field
      label="Número exterior"
      v-model="address.numero_exterior"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <kn-text-field
      label="Número interior"
      v-model="address.numero_interior"
      :rules="[]"
      :isMandatory="false"
      :disabled="disabled"
    />
  </section>
</template>

<script>
import { estados } from '@/api/consts/estados';
import { validationFormMixin } from '../../../modules/shared/mixins/validationFormMixin';
import { getItem } from '../../../api/api-methods';
import KnAutoComplete from '../.././shared/components/KnAutoComplete.vue';
import KnTextField from '../.././shared/components/KnTextField.vue';

export default {
  components: { KnAutoComplete, KnTextField },
  mixins: [validationFormMixin],
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    fillFromSearch: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      states: estados,
      zips: [],
      zipsByCity: [],
      colonias: [],
      loading: false,
      // address: {
      //   estado: null,
      //   ciudad: null,
      //   codigo_postal: null,
      //   id_codigo_postal: null,
      //   colonia: null,
      // }
    };
  },
  computed: {
    mandatoryCityRules() {
      return this.address.municipio ? [] : [this.rules.required];
    },
    mandatoryMunicipalityRules() {
      return this.address.ciudad ? [] : [this.rules.required];
    },
  },
  async created() {
    await this.fillAddress();
  },
  watch: {
    async fillFromSearch(val) {
      if (val) {
        await this.fillAddress();
      }
    },
    async address() {
      await this.fillAddress();
    },
  },
  methods: {
    async fetchZipByState(state) {
      // TODO: Mejorar el algoritmo para llenar los valores de codigo postal
      try {
        const responseData = await getItem(
          `/app-personas/filters/codigo-postal?nombre_entidad=${state}&limit=10000`
        );
        return responseData.results;
      } catch (error) {
        console.error('Error al obtener codigos por entidad', error);
      }
    },
    async getZips(state) {
      try {
        this.loading = true;
        this.zips = await this.fetchZipByState(state);
        // console.log('fetchZipsByState', this.zips);
      } catch (error) {
        console.error('Error al filtrar los codigos');
      } finally {
        this.loading = false;
      }
    },
    filterZipByCity(city) {
      try {
        this.zipsByCity = this.zips.filter((zip) => zip.nombre_ciudad === city);
        // console.log('filterZipByCity', this.zipsByCity);
      } catch (error) {
        console.error('Error al filtrar codigos por ciudad');
      }
    },
    filterZipByCityOrMunicipality(city, municipality = '') {
      try {
        this.zipsByCity = this.zips.filter(
          (zip) =>
            zip.nombre_ciudad === city || zip.nombre_municipio === municipality
        );
        // console.log('filterZipByCity', this.zipsByCity);
      } catch (error) {
        console.error('Error al filtrar codigos por ciudad');
      }
    },
    filterColByZip(code) {
      try {
        // console.log('Codigo postal por ciudad', this.zipsByCity);
        // console.log('Codigo postal', code);
        this.colonias = this.zipsByCity.filter(
          (zip) => zip.codigo_postal === code
        );
      } catch (error) {
        console.error('Error al filtrar colonias por codigo postal');
      }
    },
    getZipId() {
      try {
        const zipsfilter = this.colonias.filter((zip) => {
          return (
            (zip.nombre_ciudad === this.address.ciudad ||
              zip.nombre_municipio === this.address.municipio) &&
            (zip.codigo_postal === this.address.code ||
              zip.id === this.address.codigo_postal) &&
            zip.nombre_asentamiento === this.address.colonia
          );
        });
        // console.log('zip Filter: ', zipsfilter);
        this.address.codigo_postal = zipsfilter.length
          ? zipsfilter[0].id
          : null;
        // console.log('zip id: ', this.address.id_codigo_postal);
      } catch (error) {
        console.error('Error al obtener zip id', error);
      }
    },
    async fillAddress() {
      if (
        this.address.estado &&
        this.address.estado !== null &&
        this.address.estado !== undefined &&
        this.address.estado !== ''
      ) {
        const cp =
          typeof this.address.codigo_postal === 'object' &&
          this.address.codigo_postal !== null
            ? this.address.codigo_postal.codigo_postal
            : this.address.code;
        // console.log('cp created: ', cp);
        await this.getZips(this.address.estado);
        this.filterZipByCityOrMunicipality(
          this.address.ciudad,
          this.address.municipio
        );
        this.filterColByZip(cp);
        this.getZipId();
      }
    },
  },
};
</script>

<style></style>
